import { template as template_86cac727de0f43858e8d3f8044d5c02a } from "@ember/template-compiler";
import icon from "discourse/helpers/d-icon";
const ActionList = template_86cac727de0f43858e8d3f8044d5c02a(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;
