import { template as template_75d431e25d76466599d836743d461cdc } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_75d431e25d76466599d836743d461cdc(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
