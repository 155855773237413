import { template as template_4558904418764e22b4d911829955a3e7 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_4558904418764e22b4d911829955a3e7(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
