import { template as template_78bfa4155d6441ba83c7fce4bea15c5c } from "@ember/template-compiler";
const FKControlMenuContainer = template_78bfa4155d6441ba83c7fce4bea15c5c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
