import { template as template_6a22ec70132647cb8b716a63ead34a5d } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_6a22ec70132647cb8b716a63ead34a5d(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
