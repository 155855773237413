import { template as template_b0622f975df947ecb7623b81d0ee862e } from "@ember/template-compiler";
const FKLabel = template_b0622f975df947ecb7623b81d0ee862e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
