import { template as template_814f62958e464d35a1e8dcd9c5b3261a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_814f62958e464d35a1e8dcd9c5b3261a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
